import * as React from "react";
import styled from "styled-components";
import { Description, FontSizeHeader, Label } from "../styles/shared";
import getRichText from "../utils/getRichText";
import Booksy from "./Booksy";

const Blok = styled.article`
  @media screen and (max-width: 768px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const Tytul = styled.h2`
  ${Label}
  ${FontSizeHeader}
  height: fit-content;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
`;

const Tresc = styled.div`
  ${Description}
  max-width: 750px;
  padding-bottom: 25px;
  margin: auto;
`;

const PrzyciskBooksy = styled.div`
  margin: auto;
  display: block;
  padding-bottom: 25px;
  width: 200px;
`;

const evenBg = {
  backgroundColor: "transparent",
};
const oddBg = {
  backgroundColor: "#fff0ee",
};

const StronaGlownaBlok = (data: any) => {
  return (
    <Blok style={data.alternatywneTlo ? evenBg : oddBg}>
      <Tytul>{data.tytul}</Tytul>
      <Tresc>{getRichText({ content: data.tresc })}</Tresc>
      {data.przyciskBooksy && (
        <PrzyciskBooksy>
          <Booksy />
        </PrzyciskBooksy>
      )}
    </Blok>
  );
};

export default StronaGlownaBlok;
