import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import ListaZabiegow from "../components/ListaZabiegow";
import StronaGlownaBaner from "../components/StronaGlownaBaner";
import StronaGlownaBlok from "../components/StronaGlownaBlok";
import PageTemplate from "../layout/DefaultLayout";
import SEO from "../components/SEO";

const getComponent = (node: any) => {
  switch (node.internal.type) {
    case "ContentfulStronaGlownaBaner":
      return <StronaGlownaBaner key={node.id} {...node} />;
    case "ContentfulStronaGlownaBlokTekstu":
      return <StronaGlownaBlok key={node.id} {...node} />;
    case "ContentfulListaZabiegow":
      return <ListaZabiegow key={node.id} {...node} />;
  }
};

const IndexPage = () => {
  const {
    allContentfulStronaGlowna: {
      nodes: [nodes],
    },
  } = useStaticQuery(graphql`
    query {
      allContentfulStronaGlowna {
        nodes {
          seoTytulKarty
          seoOpisKarty
          tresc {
            ... on ContentfulListaZabiegow {
              karty {
                id
                nazwaZabiegu
                krotkiOpis {
                  krotkiOpis
                }
                miniaturka {
                  gatsbyImageData
                  description
                  contentful_id
                  __typename
                }
                stronaZabiegu {
                  slug
                }
              }
              internal {
                type
              }
              id
            }
            ... on ContentfulStronaGlownaBaner {
              zdjecia {
                baner {
                  filename
                }
              }
              internal {
                type
              }
              id
            }
            ... on ContentfulStronaGlownaBlokTekstu {
              tytul
              alternatywneTlo
              przyciskBooksy
              tresc {
                raw
              }
              internal {
                type
              }
              id
            }
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <SEO {...nodes} />
      <PageTemplate>{nodes.tresc.map(getComponent)}</PageTemplate>
    </React.Fragment>
  );
};

export default IndexPage;
