import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";

import Slider from "react-slick";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

const BanerSlider = styled(Slider)`
  overflow: hidden;
`;

const ImgWrapper = styled.a`
  cursor: pointer;
`;

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export default () => {
  const {
    allContentfulStronaGlownaBanerZdjecie: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulStronaGlownaBanerZdjecie {
        nodes {
          baner {
            gatsbyImageData
            description
            contentful_id
            __typename
          }
          link
        }
      }
    }
  `);
  return (
    <BanerSlider {...settings}>
      {nodes.map(({ baner, link }: any) => {
        const image = getImage(baner);
        return (
          image && (
            <ImgWrapper href={link} key={baner.contentful_id}>
              <GatsbyImage image={image} alt={baner.description} />
            </ImgWrapper>
          )
        );
      })}
    </BanerSlider>
  );
};
