import React, { useEffect } from "react";

type WindowWithBooksy = Window & {
  booksy: any;
};
declare var window: WindowWithBooksy;

const Booksy = () => {
  const ref = React.useRef<HTMLDivElement>(null);

  // booksy widget is not SSR friendly so we need to load it in useEffect
  useEffect(() => {
    // remove previous widget if it exists
    if (!window) return;
    if (window.booksy) {
      window.booksy = undefined;
    }
    const script = document.createElement("script");
    script.src =
      "https://booksy.com/widget/code.js?id=174822&country=pl&lang=pl";
    script.async = true;
    ref.current?.appendChild(script);
  }, []);

  return (
    // identical size as the widget to not cause layout shift
    <div ref={ref} style={{ width: "200px", height: "70px" }}></div>
  );
};

export default Booksy;
